import styled from "styled-components";
import { theme } from "../../themeConfig";

const StyledFrontPageHero2 = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;

  .items-list {
    display: flex;
    transition: transform 1s ease-in-out;
  }

  .slider-container {
    width: 100%;
    position: relative;
  }

  .navigation-dots {
    position: absolute;
    bottom: 40px;
    right: 40px;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;

    @media screen and (max-width: ${theme.breakpoints.small}) {
      right: auto;
      left: 50%;
    }
  }

  .dot {
    width: 20px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .dot.active {
    transform: scale(1.5);
    background-color: rgba(255, 255, 255, 1);
  }
`;

const HeroBackgroundImage = styled.div`
  div,
  span,
  img {
    width: 100vw !important;
    height: 100vh !important;
    object-fit: cover;
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    display: none !important;
  }
`;

const HeroBackgroundImageMobile = styled.div`
  div,
  span,
  img {
    width: 100vw !important;
    height: 100vh !important;
    min-height: 600px;
    object-fit: cover;
  }

  @media screen and (min-width: 601px) {
    display: none !important;
  }
`;

interface HeroContentContainerProps {
  color: string;
}

const HeroContentContainer = styled.div<HeroContentContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: 3em;

  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;

  font-size: 80px;

  h1 {
    display: flex;
    flex-direction: column;
    font-size: clamp(60px, 10vw, 140px);

    font-family: ${theme.fonts.secondary};

    span {
      display: flex;
      align-items: center;
      margin-bottom: -32px;
      > div {
        margin-left: 0.25em;
        margin-right: 0.25em;
        font-size: 1.5em;
      }
    }

    span:nth-child(2) {
      > div {
        margin: 0;
      }
    }

    color: ${({ color }) => color};
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    left: 22px;

    h1 {
      span {
        margin-bottom: -8px;
      }
    }
  }
`;

export {
  StyledFrontPageHero2,
  HeroBackgroundImage,
  HeroBackgroundImageMobile,
  HeroContentContainer,
};
