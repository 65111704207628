import React, { useState, useEffect, useRef } from "react";
import { WPImage } from "../WPImage/WPImage";
import {
  StyledFrontPageHero2,
  HeroBackgroundImage,
  HeroContentContainer,
  HeroBackgroundImageMobile,
} from "./FrontPageHero2.styled";
import Link from "next/link";
import Button from "../Button/Button";

interface ItemProps {
  image: any;
  image_mobile: any;
  hero_color: string;
  title_1: string;
  title_2: string;
  title_3: string;
  hero_button_background: string;
  hero_button_color: string;
  hero_button_text: string;
  hero_button_link: string;
  icon_left: string;
  icon_right: string;
}

const Item: React.FC<ItemProps> = ({
  image,
  image_mobile,
  hero_color = "white",
  title_1,
  title_2,
  title_3,
  hero_button_background = "black",
  hero_button_color = "white",
  hero_button_text = "Text saknas",
  hero_button_link,
  icon_left,
  icon_right,
}) => (
  <div className="item" style={{ position: "relative" }}>
    <HeroBackgroundImage>
      <WPImage priority={true} image={image} />
    </HeroBackgroundImage>

    <HeroBackgroundImageMobile>
      <WPImage priority={true} image={image_mobile} />
    </HeroBackgroundImageMobile>
    <HeroContentContainer color={hero_color}>
      <h1>
        <span dangerouslySetInnerHTML={{ __html: title_1 }}></span>
        <span dangerouslySetInnerHTML={{ __html: title_2 }}></span>
      </h1>
      <Link href={hero_button_link}>
        <Button
          icon_left={icon_left}
          icon_right={icon_right}
          title={hero_button_text}
          color={hero_button_color}
          background={hero_button_background}
        />
      </Link>
    </HeroContentContainer>
  </div>
);

interface ItemsListProps {
  items: any[];
}

const ItemsList: React.FC<ItemsListProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    startAutoScroll();

    return () => stopAutoScroll();
  }, [items.length]);

  const startAutoScroll = () => {
    stopAutoScroll();
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 50000); // Change slide every 5 seconds
  };

  const stopAutoScroll = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
    startAutoScroll();
  };

  return (
    <div className="slider-container">
      <div
        className="items-list"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 1s ease-in-out",
          display: "flex",
          width: "100vw",
        }}
      >
        {items.map((item, index) => (
          <div className="item" key={index} style={{ flex: "0 0 100%" }}>
            <Item
              hero_color={item.hero_color}
              image={item.hero_background}
              image_mobile={
                item.hero_background_mobile
                  ? item.hero_background_mobile
                  : item.hero_background
              }
              title_1={item.hero_title_1}
              title_2={item.hero_title_2}
              title_3={item.hero_title_3}
              hero_button_text={item.hero_button_text}
              hero_button_link={item.hero_button_link}
              hero_button_background={item.hero_button_background}
              hero_button_color={item.hero_button_color}
              icon_left={item.icon_left}
              icon_right={item.icon_right}
            />
          </div>
        ))}
      </div>

      <div className="navigation-dots">
        {items.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

const FrontPageHero2 = ({ pageProps }: any) => {
  return (
    <StyledFrontPageHero2>
      <ItemsList items={pageProps} />
    </StyledFrontPageHero2>
  );
};

export default FrontPageHero2;
